.nav-bar {
    background-color: #6333be;
}

.nav-bar-brand {
    color: white;
    font-weight: bold;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    height: 32px;
}

body {
   background-color: #1e232d;
}

.carousel-item-image {
    height: 50vh;
    align-self: center;
    margin: auto auto;
}

.border-indigo {
    border-color: #6915fd;
}

.description-group {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.description-group  .list-group-item, .carousel-container {
    background-color: var(--bs-gray-900);
    border-color: var(--bs-gray-700) !important;
}

.carousel-container {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background: linear-gradient(var(--bs-gray-800), var(--bs-dark));
}

.description-group * {
    color: white;
}
